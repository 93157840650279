@use '../style/private-theme' as theme;
@use '../style/utils';

///* Selection list */
#{utils.raiseSpecificity('.mat-mdc-selection-list')} {
  --mdc-list-list-item-label-text-color: #{theme.$font-standard-color} !important;

  .mat-mdc-list-option.mat-accent .mdc-list-item__start,
  .mat-mdc-list-option.mat-accent .mdc-list-item__end {
    --mdc-checkbox-selected-checkmark-color: #{theme.$background} !important;
    --mdc-checkbox-selected-focus-icon-color: #{theme.$accent-color} !important;
    --mdc-checkbox-selected-hover-icon-color: #{theme.$accent-color} !important;
    --mdc-checkbox-unselected-icon-color: #{theme.$accent-color} !important;
    --mdc-checkbox-selected-icon-color: #{theme.$accent-color} !important;
    --mdc-checkbox-selected-pressed-icon-color: #{theme.$accent-color} !important;
    --mdc-checkbox-unselected-focus-icon-color: #{theme.$accent-color} !important;
    --mdc-checkbox-unselected-hover-icon-color: #{theme.$accent-color} !important;
    --mdc-checkbox-disabled-selected-icon-color: #{theme.$disabled-color} !important;
    --mdc-checkbox-disabled-unselected-icon-color: #{theme.$disabled-color} !important;
  }

  .mdc-list-item--with-leading-checkbox .mdc-list-item__start {
    margin-right: 8px;
  }
}
