@use '@angular/material' as mat;
@use 'mixins/theme-builder.mixin';
@use 'functions/theme-builder.function' as theme-builder-fn;
@use 'sass:map';

// mdc web components uses -webkit-font-smoothing: antialiased; which causes text to look lighter in some locations
// this resets it to using what the browser defaults to
body * {
  -webkit-font-smoothing: initial;
}

@include mat.elevation-classes();
@include mat.app-background();

$typography: theme-builder-fn.create-typography(Verdana, 400, 400);
$primary-palette: theme-builder-fn.create-flat-palette(#ffe923, black);
$warn-palette: theme-builder-fn.create-flat-palette(#c4401a, white);
$theme: theme-builder-fn.create-theme(false, $primary-palette, $warn-palette, $typography);

@include theme-builder.apply-theme($theme);

// Theme variables
:root,
:host {
  --primary-color: #ffe923;
  --primary-text-color: black;
  --primary-hover-color: #fff497;
  --primary-hover-text-color: black;
  --text-color-on-primary-background: black;

  --font-standard-color: black;
  --font-secondary-text-color: black;
  --font-link-color: black;
  --font-link-hover-color: black;
  --font-link-weight: initial;
  --font-standard-weight: 400;

  --accent-color: black;
  --accent-text-color: white;
  --link-text-decoration: underline;

  --status-warning-color: #efb71f;

  --status-success-color: #006601;

  --status-error-color: #b30100;

  --disabled-color: #b0bac1;
  --line-color: #e2e5e8;

  --background: white;

  --status-info-color: #575757;
  --status-info-text-color: white;

  // private colors

  --form-field-underline-color: black;

  --advert-background: #ffe923;
  --advert-text: black;

  --primary-button-border-color: #ffe923;
  --primary-button-border-hover-color: #fff497;

  --button-standard-background-color: white;
  --button-standard-text-color: black;
  --button-standard-border-color: black;

  --button-standard-hover-background-color: #fff497;
  --button-standard-hover-text-color: black;
  --button-standard-hover-border-color: black;

  --button-border-radius: 8px;
  --button-horizontal-padding: 8px; // small

  --icon-font-color: black;
  --header-icon-color: black;

  --slide-accent-color: black;

  --radio-icon-color: black;

  --table-row-hover: #f5f5f5;

  --font-family: Verdana;

  --tooltip-container-color: black;

  @include theme-builder.set-ripple-color($theme);
}
