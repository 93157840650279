@use '../style/private-theme' as theme;
@use '../style/sizes';
@use '../style/utils';

#{utils.raiseSpecificity('div.mat-mdc-menu-panel')} {
  // for some reason this does not get set properly via the theme typography
  button {
    font-family: theme.$font-family !important;
  }

  border-radius: 3px;
  min-height: 48px;
  min-width: auto;

  .mat-mdc-menu-content {
    &:not(:empty) {
      padding-bottom: 0;
      padding-top: 0;
    }

    .mat-mdc-menu-item {
      line-height: sizes.$line-height;
      font-size: sizes.$font-size;
      padding-top: sizes.$small-plus;
      padding-bottom: sizes.$small-plus;
      border-bottom: 1px solid theme.$line-color;

      span {
        color: theme.$font-standard-color;
        font-size: sizes.$font-size;
        -webkit-font-smoothing: initial;
      }

      .mat-icon {
        color: theme.$font-standard-color;
        font-size: 24px;
        margin-right: sizes.$small;
      }

      &.selected {
        background-color: theme.$primary-color;
        span,
        .mat-icon {
          color: theme.$text-color-on-primary-background;
        }
      }

      &[disabled] {
        color: theme.$disabled-color;

        & span,
        & .mat-icon {
          color: theme.$disabled-color;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .mat-menu-content:not(:empty) {
    padding: 0;
  }
}
