@use 'sass:math';
@use 'sass:map';
@use '../style/private-theme' as theme;
@use '../style/sizes';
@use '../style/breakpoints';
@use '../style/utils';

$card-vertical-padding: sizes.$medium-plus;
$card-horizontal-padding: sizes.$medium;

#{utils.raiseSpecificity('mat-card.mat-mdc-card')} {
  background-color: theme.$background;
  border-radius: 3px;
  box-shadow: none;
  padding: $card-vertical-padding $card-horizontal-padding;
  overflow: hidden;

  .mat-mdc-card-content {
    font-size: sizes.$font-size;
    padding: 0;

    & > *:not(:last-child) {
      margin-bottom: sizes.$medium;
    }
  }

  .mat-mdc-card-title > h3 {
    margin: 0;
  }

  // this is the standard mat-card-image. We override it, but the style guide wants images to be locked to a
  // specific aspect ratio so it is better to use the card-img- helper classes
  // this has to match the padding of the card
  & > .mat-mcd-card-image {
    &:hover {
      opacity: 0.8;
    }

    &:first-child {
      margin: (-(sizes.$medium-plus)) (-(sizes.$medium)) sizes.$medium-plus (-(sizes.$medium));
    }
  }

  .mat-mdc-card-actions {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;

    & > * {
      margin-right: sizes.$small;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  & > *:not(:last-child) {
    flex: 0 0 initial;
    overflow: hidden;
    margin-bottom: sizes.$medium;
  }
}

//
// Additional custom/helper styles
//

#{utils.raiseSpecificity('.mat-mdc-card')} {
  /*
       ### image helper classes for standard (vertical) card variant
    */
  .card-img-outer {
    position: relative;
    // padding "hack" to give aspect ratio based height. Main card image ratio is 3:2
    height: 0;
    padding-bottom: math.div(3, 2) * (math.div(1, 3) * 100%);
    margin: (-(sizes.$medium-plus)) (-(sizes.$medium)) sizes.$medium-plus (-(sizes.$medium));

    .card-img-inner {
      position: absolute;
      border-bottom: 1px solid theme.$line-color;
      height: 100%;
      width: 100%;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    img.card-img-contain {
      object-fit: contain;
    }

    .advertising {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: theme.$advert-background;
      color: theme.$advert-text;
      padding: sizes.$small sizes.$medium;
      margin: 0;
    }
  }

  /*
       ### Portrait card variant
    */

  & .portrait-card-content {
    // xs version should look like a vertical card
    display: flex;
    flex-direction: column;
    // needed to allow the image border frame to extend to the edge of the card
    overflow: initial;

    @media (min-width: map.get(breakpoints.$grid-breakpoints, 'sm')) {
      flex-direction: row;
    }

    .portrait-img-outer {
      position: relative;
      padding-bottom: math.div(4, 3) * 100%;
      margin: (-(sizes.$medium-plus)) (-(sizes.$medium)) sizes.$medium-plus (-(sizes.$medium));

      // padding "hack" to give aspect ratio based height. Portrait image ratio is 3:4
      height: 0;

      // in portrait view the image takes 1/3 of the width
      @media (min-width: map.get(breakpoints.$grid-breakpoints, 'sm')) {
        flex: 0 0 (math.div(1, 3) * 100%);
        padding-bottom: math.div(4, 3) * (math.div(1, 3) * 100%);
        margin: 0 24px 0 0;
      }

      .portrait-img-inner {
        position: absolute;
        border-bottom: 1px solid theme.$line-color;
        height: 100%;
        width: 100%;

        @media (min-width: map.get(breakpoints.$grid-breakpoints, 'sm')) {
          border: 1px solid theme.$line-color;
        }

        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;

          &.card-img-contain {
            object-fit: contain;
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    .mat-mdc-card-content {
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      flex: 1 1 auto;

      .mat-card-actions {
        // margin-top auto to put the actions at the bottom of the card, negative margin bottom offsets the
        // button so that the icon lines up inside the parent container padding
        margin: auto 0 0 0;
      }
    }
  }

  /*
       ### Horizontal card variant
    */

  &.horizontal-card {
    // xs version should look like a vertical card
    display: flex;
    flex-direction: column;

    @media (min-width: map.get(breakpoints.$grid-breakpoints, 'sm')) {
      flex-direction: row;
    }

    .mat-mdc-card-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      @media (min-width: map.get(breakpoints.$grid-breakpoints, 'sm')) {
        flex-direction: row;
        flex-wrap: wrap;

        & > * {
          margin-right: sizes.$medium;
        }
      }

      .mat-mdc-card-actions {
        margin: auto 0 0 0;
      }
    }

    .mat-mdc-card-content .mat-mdc-card-title,
    .mat-mdc-card-content .mat-mdc-card-actions {
      flex: 0 0 100%;
    }

    .horizontal-card-img-outer {
      height: 0;
      position: relative;
      padding-bottom: math.div(3, 2) * (math.div(1, 3) * 100%);
      margin: (-(sizes.$medium-plus)) (-(sizes.$medium)) sizes.$medium-plus (-(sizes.$medium));

      @media (min-width: map.get(breakpoints.$grid-breakpoints, 'sm')) {
        height: inherit;
        padding-bottom: 0;
        // we make the picture 25% of the full parent width including padding (2 * $medium)
        flex: 0 0 calc((100% + #{2 * sizes.$medium}) / 4);
        margin: (-$card-vertical-padding) $card-horizontal-padding (-$card-vertical-padding) (-$card-horizontal-padding);
      }

      .horizontal-card-img-inner {
        position: absolute;

        border-bottom: 1px solid theme.$line-color;
        height: 100%;
        width: 100%;

        @media (min-width: map.get(breakpoints.$grid-breakpoints, 'sm')) {
          border-right: 1px solid theme.$line-color;
          border-bottom: none;
        }

        & > .horizontal-card-img {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;

          &.card-img-contain {
            object-fit: contain;
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
