@use '../style/private-theme' as theme;
@use '../style/sizes';
@use '../style/utils';
@use '../mixins/border-shadow.mixin';

#{utils.raiseSpecificity('.mat-mdc-chip')} {
  &.mat-mdc-standard-chip {
    --mdc-chip-container-shape-radius: 8px;
    --mdc-chip-with-avatar-avatar-size: 20px;
    --mdc-chip-with-icon-icon-size: 20px;
    --mat-chip-trailing-action-opacity: 1;

    background-color: var(--background);

    @include border-shadow.border-shadow(theme.$status-info-color);

    &[color='primary'],
    &.mat-mdc-chip-selected.mat-primary,
    &.primary {
      $primary-background: theme.$status-info-color;
      @include border-shadow.remove-border-shadow();
      background-color: $primary-background;
      color: theme.$background;
      --mdc-chip-with-icon-icon-color: theme.$background;
    }

    &.info,
    &.gray {
      $info-background: color-mix(in srgb, theme.$font-standard-color, transparent 85%);
      @include border-shadow.remove-border-shadow();
      background-color: $info-background;
      color: theme.$font-standard-color;
      --mdc-chip-with-icon-icon-color: theme.$status-info-color;
    }

    &.yellow,
    &.warning {
      $warning-background: color-mix(in srgb, theme.$status-warning-color, transparent 80%);
      @include border-shadow.remove-border-shadow();
      background-color: $warning-background;
      color: #855e0eff;
      --mdc-chip-with-icon-icon-color: theme.$status-warning-color;
    }

    &.green,
    &.success {
      $success-background: color-mix(in srgb, theme.$status-success-color, transparent 85%);
      @include border-shadow.remove-border-shadow();
      background-color: $success-background;
      color: theme.$status-success-color;
      --mdc-chip-with-icon-icon-color: theme.$status-success-color;
    }

    &.red,
    &.error {
      $error-background: color-mix(in srgb, theme.$status-error-color, transparent 85%);
      @include border-shadow.remove-border-shadow();
      background-color: $error-background;
      color: theme.$status-error-color;
      --mdc-chip-with-icon-icon-color: theme.$status-error-color;
    }

    // this lets us set the color of the text in the chip and prevents it being
    // overwritten lower down
    .mat-mdc-chip-action-label {
      color: inherit;
    }

    .mat-ripple-element {
      visibility: hidden;
    }
  }
}
