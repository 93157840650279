@use 'sass:map';
@use '../style/private-theme' as theme;
@use '../style/breakpoints';
@use '../style/sizes';

$cell-y-padding: sizes.$medium;
$cell-x-padding: sizes.$medium;
$mobile-cell-y-padding: sizes.$small;
$mobile-cell-x-padding: sizes.$small;

.mat-mdc-table {
  width: 100%;
  line-height: sizes.$line-height;

  tr.mat-mdc-header-row {
    height: auto;
  }

  th {
    -webkit-font-smoothing: initial; // required du to high specificity rules
    border-bottom: 1px solid theme.$font-standard-color;
    vertical-align: top; // make header align to the top

    & .mat-mdc-sort-header-container {
      align-items: flex-start; // make the arrows align to the top
    }

    .mat-mdc-sort-header-arrow {
      color: theme.$accent-color;
      margin-left: sizes.$small;
      margin-top: 6px;
    }

    // high specificity rules for first and last children to override specific material styles
    &.mat-mdc-header-cell:first-child {
      padding-left: $cell-x-padding;
    }

    &.mat-mdc-header-cell:last-child {
      padding-right: $cell-x-padding;
    }

    &.mat-mdc-header-cell {
      font-size: sizes.$font-size;
      font-weight: 800;
    }
  }

  // the cells need to be fully opaque to prevent cells showing through sticky columns
  tr:hover {
    td,
    th {
      background-color: theme.$table-row-hover;
    }
  }

  tr:not(:hover) {
    td,
    th {
      background-color: theme.$background;
    }
  }

  td {
    border-bottom: 1px solid theme.$line-color;
    -webkit-font-smoothing: initial; // required du to high specificity rules

    &.mat-mdc-cell:first-child {
      padding-left: $cell-x-padding;
    }

    &.mat-mdc-cell:last-child {
      padding-right: $cell-x-padding;
    }
  }

  .mat-mdc-cell,
  .mat-mdc-header-cell,
  .mat-mdc-footer-cell {
    font-size: sizes.$font-size;
    padding: $mobile-cell-y-padding $mobile-cell-x-padding;

    @media (min-width: map.get(breakpoints.$grid-breakpoints, 'sm')) {
      padding: $cell-y-padding $cell-x-padding;
    }
  }

  .mat-mdc-footer-cell {
    font-weight: bold;
    border-top: 1px solid theme.$font-standard-color;

    &:first-child {
      padding-left: $cell-x-padding;
    }

    &:last-child {
      padding-right: $cell-x-padding;
    }
  }

  .column-right {
    &.mat-mdc-cell {
      text-align: right;
    }

    .mat-sort-header-container {
      justify-content: end;

      .mat-sort-header-content {
        text-align: right;
      }
    }
  }
}
