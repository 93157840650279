@use '../style/private-theme' as theme;
@use '../style/sizes';
@use '../style/utils';

// mat-slider provides 'basic' variant
#{utils.raiseSpecificity('.mat-mdc-slider')} {
  --mdc-slider-handle-color: #{theme.$background};
  --mdc-slider-focus-handle-color: #{theme.$accent-color};
  --mdc-slider-hover-handle-color: #{theme.$accent-color};
  --mdc-slider-active-track-color: #{theme.$accent-color};
  --mat-slider-ripple-color: #{theme.$accent-color};
  --mat-slider-focus-state-layer-color: #{theme.$accent-color};
  --mdc-slider-inactive-track-color: #{theme.$line-color};
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #ffe923;
  --mat-mdc-slider-ripple-color: inherit;
  --mat-mdc-slider-focus-ripple-color: inherit;
  --mat-mdc-slider-hover-ripple-color: inherit;
  --mdc-slider-inactive-track-height: 2px;
  --mdc-slider-active-track-height: 2px;
  --mdc-slider-handle-height: 16px;
  --mdc-slider-handle-width: 16px;

  .mat-mdc-focus-indicator {
    opacity: 0.2;
  }

  .mdc-slider__track--inactive {
    opacity: 1;
  }

  .mdc-slider__thumb--with-indicator {
    .mdc-slider__thumb-knob {
      background-color: #{theme.$accent-color};
    }
  }

  .mdc-slider__thumb-knob {
    border: 2px solid #{theme.$accent-color};
    transition: background-color 0.2s ease-in-out;
  }
}

.mat-mdc-slider-focus-ripple,
.mat-mdc-slider-hover-ripple,
.mat-mdc-active-ripple {
  opacity: 0.2;
}

// users should use ngx-slider for 'figure' and 'range' variants
.ngx-slider:not(.dummy-specificity-class) {
  margin: 10px 0 0;
  height: 40px;

  top: 8px;

  .ngx-slider-bubble {
    top: 15px;

    // color for the left and right limit labels
    &.ngx-slider-limit {
      color: theme.$font-standard-color;
    }

    // color for the value labels
    &.ngx-slider-model-value,
    &.ngx-slider-model-value-max,
    &.ngx-slider-model-high,
    &.ngx-slider-combined {
      color: theme.$font-standard-color;
    }
  }

  .ngx-slider-pointer {
    background-color: theme.$accent-color;
    top: -7px;
    width: sizes.$medium;
    height: sizes.$medium;

    &:hover::after {
      background-color: theme.$accent-color;
      cursor: pointer;
    }

    &.ngx-slider-active {
      box-sizing: border-box;
      background-color: theme.$accent-color;
      outline: 0;
      width: sizes.$medium;
      height: sizes.$medium;

      &:after {
        background-color: theme.$accent-color;
      }
    }

    &:after {
      width: 12px;
      height: 12px;
      top: 2px;
      left: 2px;
      border-radius: 50%;
      background-color: theme.$background;
    }
  }

  .ngx-slider-bar {
    height: 2px;
    background: theme.$line-color;

    &.ngx-slider-selection,
    &.ngx-slider-selection {
      background: theme.$accent-color;
      visibility: visible !important; // override inline style
    }
  }

  &.animate {
    .ngx-slider-pointer {
      transition: none;
    }
  }
}
