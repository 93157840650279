@use '../style/private-theme' as theme;
@use '../style/utils';
@use '@angular/material' as mat;

.compact-form-fields {
  @include mat.form-field-density(-2);
}

#{utils.raiseSpecificity('.mat-mdc-form-field')} {
  --mdc-outlined-text-field-caret-color: theme.$font-standard-color;
  --mdc-outlined-text-field-focus-label-text-color: var(--mdc-outlined-text-field-hover-outline-color);
  --mdc-outlined-text-field-focus-outline-color: var(--mdc-outlined-text-field-hover-outline-color);

  &.no-reserved-space {
    .mat-mdc-form-field-subscript-wrapper {
      height: 0;
    }
  }
}
