@use 'sass:color';
@use '../style/breakpoints';
@use '../style/sizes';
@use '../style/private-theme' as theme;
@use 'sass:map';

mat-sidenav-container {
  &.mat-drawer-container {
    background-color: theme.$background;
  }

  .mat-toolbar:first-child .mat-icon-button {
    align-self: center;
    margin-right: 4px;
  }

  .logo {
    align-self: center;
    img {
      height: 22px;
    }
  }

  .app-name {
    font: 20px Verdana;
    color: theme.$accent-color;
    margin: 0;
    align-self: center;
    cursor: pointer;

    &:hover {
      color: theme.$accent-color;
      text-decoration: underline;
    }
  }
}

mat-sidenav {
  box-shadow: 3px 0 6px color.adjust(black, $alpha: -0.76);
  padding-top: sizes.$small;
  --mat-sidenav-container-width: 100%;

  @media (min-width: map.get(breakpoints.$grid-breakpoints, 'md')) {
    --mat-sidenav-container-width: 400px;
  }

  .sidenav-header {
    display: flex;
    height: 60px;
    padding: 0 12px;
    align-items: center;
  }
}

mat-sidenav-content {
  background-color: theme.$background;
  min-height: 100vh;
}

mat-toolbar {
  .header-button + .app-name {
    margin-left: 4px;
  }
}
