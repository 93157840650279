@use '../style/private-theme' as theme;
@use '../style/utils';

#{utils.raiseSpecificity('.mat-mdc-snack-bar-container')} {
  --mdc-snackbar-container-color: #{theme.$background};
  --mdc-snackbar-supporting-text-color: #{theme.$font-standard-color};
  --mat-snack-bar-button-color: #{theme.$font-standard-color};
  --button-standard-background-color: #{theme.$background};

  .mdc-button.mat-mdc-snack-bar-action {
    box-shadow: none !important;
    font-weight: bold;
  }
}
