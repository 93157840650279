@use '../style/sizes';
@use '../style/private-theme' as theme;
@use '../style/breakpoints';
@use '../style/utils';
@use '../mixins/utilities.mixin';

#{utils.raiseSpecificity('.mat-mdc-tab-group,.mat-mdc-tab-nav-bar')} {
  // the nav bar variant has the tab-header class on the nav element itself, in the standard
  // tab type it is a child of the tab group element
  &.mat-mdc-tab-header,
  .mat-mdc-tab-header {
    border-bottom: solid 1px theme.$line-color;
  }

  .mdc-tab-indicator__content--underline {
    display: none;
  }

  .mat-mdc-tab-list {
    --mdc-tab-indicator-active-indicator-color: #{theme.$background};
    flex-grow: 0;

    .mat-mdc-tab,
    .mat-mdc-tab-link {
      padding: 0 !important;
      margin: 0;
      min-width: 10px;

      .mdc-tab__content {
        align-items: flex-end;
      }

      .mdc-tab__text-label {
        transition: background-color 0.2s;
        min-width: 10px;
        padding: sizes.$small-plus sizes.$small;
        margin: 0 sizes.$small 0 0;
        border-radius: 8px 8px 0 0;
        color: theme.$font-standard-color;
      }

      &:focus,
      &:hover {
        .mdc-tab__text-label {
          border-color: theme.$accent-color;
          text-decoration: underline;
        }
      }

      &:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
      .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
        color: theme.$text-color-on-primary-background;
        background-color: theme.$primary-color;

        &:focus,
        &:hover {
          border-color: theme.$primary-text-color;
        }
      }
    }

    .mdc-tab__ripple,
    .mat-mdc-tab-ripple {
      display: none;
    }
  }
}

.mat-mdc-tab-body-content {
  box-sizing: border-box;
  padding: sizes.$medium 0;
}
