@use 'sass:math';
@use '../style/private-theme' as theme;
@use '../style/sizes';
@use '../style/utils';
@use '../mixins/border-shadow.mixin';

$stepper-horizontal-space: 24px;
$step-icon-size: 48px;

#{utils.raiseSpecificity('.mat-stepper-horizontal,.mat-stepper-vertical')} {
  background-color: theme.$background;

  .mat-stepper-horizontal-line {
    border-top: 1px solid theme.$line-color;
  }

  .mat-step-header {
    height: initial;
    .mat-step-label {
      font-size: sizes.$font-size;
      color: theme.$disabled-color;

      &.mat-step-label-active {
        color: theme.$accent-color;
      }

      &.mat-step-label-selected {
        color: theme.$accent-color;
        font-weight: theme.$font-standard-weight;
      }
    }

    .mat-step-icon {
      height: $step-icon-size;
      width: $step-icon-size;
      background: inherit;
      @include border-shadow.border-shadow(theme.$line-color);
      color: theme.$font-standard-color;

      .mat-icon {
        font-size: 24px;
        height: 24px;
        width: 24px;
      }

      &.mat-step-icon-selected {
        background: theme.$primary-color;
        color: theme.$text-color-on-primary-background;
        box-shadow: none;
      }
    }
  }
}

.mat-stepper-horizontal {
  .mat-horizontal-stepper-header-container {
    margin-bottom: sizes.$medium;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    padding-top: sizes.$medium-plus;
    margin-top: sizes.$medium-plus;
    border-top: 1px solid theme.$line-color;

    button {
      margin-left: sizes.$medium;
    }
  }
}

.mat-stepper-vertical {
  .mat-vertical-content {
    margin: sizes.$medium sizes.$small sizes.$medium 0;
    padding: 0 $stepper-horizontal-space;
  }

  .mat-step-header.mat-vertical-stepper-header .mat-step-icon {
    margin-right: sizes.$small;
  }

  .mat-vertical-stepper-header {
    padding: sizes.$medium $stepper-horizontal-space;
  }

  .mat-stepper-vertical-line::before {
    border-left-color: theme.$line-color;
  }

  .mat-vertical-content-container {
    margin-left: $stepper-horizontal-space + math.div($step-icon-size, 2);
    min-height: 12px;

    &:before {
      top: -6px;
      bottom: -6px;
    }
  }
}

.mat-stepper-horizontal.mat-stepper-label-position-bottom {
  .mat-horizontal-stepper-header {
    padding: 14px 24px;
    margin: 5px 0;
  }

  .mat-stepper-horizontal-line {
    top: 41px;
  }

  .mat-horizontal-stepper-header-container {
    .mat-horizontal-stepper-header::before,
    .mat-horizontal-stepper-header::after {
      width: calc(50% - 30px);
      border-top-color: theme.$line-color;
    }
  }
}
