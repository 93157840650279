@use '../style/private-theme' as theme;
@use '../style/utils';

#{utils.raiseSpecificity('.mat-mdc-checkbox')} {
  --mdc-checkbox-selected-checkmark-color: #{theme.$background};
  --mdc-checkbox-selected-focus-icon-color: #{theme.$accent-color};
  --mdc-checkbox-unselected-icon-color: #{theme.$accent-color};
  --mdc-checkbox-selected-hover-icon-color: #{theme.$accent-color};
  --mdc-checkbox-selected-icon-color: #{theme.$accent-color};
  --mdc-checkbox-selected-pressed-icon-color: #{theme.$accent-color};
  --mdc-checkbox-unselected-focus-icon-color: #{theme.$accent-color};
  --mdc-checkbox-unselected-hover-icon-color: #{theme.$accent-color};
  --mdc-checkbox-disabled-selected-icon-color: #{theme.$disabled-color};
  --mdc-checkbox-disabled-unselected-icon-color: #{theme.$disabled-color};
  --mdc-checkbox-selected-focus-state-layer-color: #{theme.$accent-color};
  --mdc-checkbox-selected-hover-state-layer-color: #{theme.$accent-color};
  --mdc-checkbox-selected-pressed-state-layer-color: #{theme.$accent-color};

  &.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
    background: #{theme.$accent-color};
  }

  // without this we get a yellow ripple during the animation
  .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
    background-color: inherit;
  }
}
