@use '../style/private-theme' as theme;
@use '../style/utils';
@use '../style/sizes';

#{utils.raiseSpecificity('.mat-mdc-slide-toggle')} {
  --mdc-switch-selected-icon-color: #{theme.$text-color-on-primary-background};
  --mdc-switch-selected-track-color: var(--mdc-switch-unselected-track-color);
  --mdc-switch-selected-hover-track-color: var(--mdc-switch-unselected-track-color);
  --mdc-switch-selected-focus-track-color: var(--mdc-switch-unselected-track-color);
  --mdc-switch-selected-pressed-track-color: var(--mdc-switch-unselected-track-color);
  --mdc-switch-unselected-handle-color: var(--mdc-switch-unselected-hover-handle-color);

  button.mdc-switch {
    height: 24px; // fix a bug in safari where disabled switches are cut off at the bottom
  }

  .mdc-switch:enabled .mdc-switch__track::after {
    background: var(--mdc-switch-unselected-track-color) !important;
  }

  label {
    margin-left: sizes.$extra-small;
  }
}
