@use '../style/private-theme' as theme;
@use '../style/utils';

#{utils.raiseSpecificity('.mat-mdc-radio-button')} {
  --mdc-radio-selected-checkmark-color: #{theme.$background};
  --mdc-radio-selected-focus-icon-color: #{theme.$accent-color};
  --mdc-radio-unselected-icon-color: #{theme.$accent-color};
  --mdc-radio-selected-hover-icon-color: #{theme.$accent-color};
  --mdc-radio-selected-icon-color: #{theme.$accent-color};
  --mdc-radio-selected-pressed-icon-color: #{theme.$accent-color};
  --mdc-radio-unselected-focus-icon-color: #{theme.$accent-color};
  --mdc-radio-unselected-hover-icon-color: #{theme.$accent-color};
  --mdc-radio-disabled-selected-icon-color: #{theme.$disabled-color};
  --mdc-radio-disabled-unselected-icon-color: #{theme.$disabled-color};
  --mat-mdc-radio-checked-ripple-color: #{theme.$accent-color};
  --mat-radio-checked-ripple-color: #{theme.$accent-color};
}
