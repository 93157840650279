@use '../style/private-theme' as theme;
@use '../style/sizes';
@use '../style/utils';

#{utils.raiseSpecificity('.mat-mdc-select')} {
  .mat-mdc-select-arrow-wrapper {
    .mat-mdc-select-arrow {
      color: theme.$accent-color !important;
    }
  }

  &.mat-mdc-select-disabled {
    .mat-mdc-select-arrow-wrapper {
      .mat-mdc-select-arrow {
        color: theme.$disabled-color !important;
      }
    }
  }
}

#{utils.raiseSpecificity('.mat-mdc-select-panel')} {
  max-height: 264px;
  padding-top: 0;
  padding-bottom: 0;

  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate,
  .mat-accent .mat-pseudo-checkbox-checked,
  .mat-accent .mat-pseudo-checkbox-indeterminate {
    background-color: theme.$accent-color;
  }

  .mat-mdc-option,
  .mat-mdc-optgroup-label {
    line-height: sizes.$line-height;
    height: auto;
    min-height: auto;
    padding-top: sizes.$small-plus;
    padding-bottom: sizes.$small-plus;
  }

  .mat-mdc-optgroup-label {
    border-bottom: 1px solid theme.$line-color;

    .mdc-list-item__primary-text {
      font-weight: bold;
      color: theme.$primary-text-color;
    }

    &:not(:first-of-type) {
      border-top: 1px solid theme.$line-color;
    }
  }

  .mat-mdc-option {
    &.mat-mdc-option-multiple {
      padding-left: sizes.$large;
    }

    &.mdc-list-item--selected:not(.mat-mdc-option-disabled) {
      background: theme.$primary-color;

      .mdc-list-item__primary-text {
        color: theme.$text-color-on-primary-background;
      }
    }

    &:focus:not(.mat-mdc-option-disabled) {
      background: none;

      .mat-pseudo-checkbox {
        color: theme.$accent-color;
      }
    }
  }
}

#{utils.raiseSpecificity('.mat-mdc-option')} {
  --mat-full-pseudo-checkbox-unselected-icon-color: theme.$accent-color;

  // checkboxes for multi select
  &.mat-mdc-option-disabled .mat-pseudo-checkbox {
    color: theme.$disabled-color;
  }

  .mat-pseudo-checkbox {
    color: theme.$accent-color;

    &::after {
      background-color: theme.$text-color-on-primary-background;
    }

    &.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
      border-color: theme.$text-color-on-primary-background;
      background: theme.$text-color-on-primary-background;

      &::after {
        color: white;
      }
    }
  }

  // checkboxes for single select selected status
  .mat-pseudo-checkbox-minimal {
    background-color: theme.$primary-color;

    &::after {
      background-color: theme.$primary-color;
      color: theme.$text-color-on-primary-background;
    }
  }
}
