@use 'sass:map';
@use '../style/breakpoints';
@use '../style/sizes';
@use '../style/theme';
@use '../functions/grid-widths.function';

.cdk-overlay-container {
  .cdk-overlay-backdrop-showing {
    opacity: 0.5;
    background-color: rgba(0, 0, 0, 0.24);
  }

  .cdk-global-overlay-wrapper {
    height: 100%;

    // we have to use `!important` for the following styles because the default style is set inline

    .cdk-overlay-pane {
      // create a full screen dialog
      // this must have a close action as the user is unable to click on the backdrop
      &.full-screen {
        height: 100vh !important;
        max-height: 100vh !important;
        width: 100vw !important;
        max-width: 100vw !important;

        .mat-mdc-dialog-surface,
        .mat-mdc-dialog-content {
          height: 100%;
          width: 100%;
          max-height: 100%;
        }
      }

      // apply a max width to the dialog - legacy implementation
      &.constrained-dialog {
        margin: sizes.$medium 0;
        // xs, sm style
        // $medium frame around popup
        max-width: calc(100vw - (#{sizes.$medium} * 2)) !important;

        .mat-mdc-dialog-surface {
          width: 100%;
          height: auto;
          max-height: 100%;
          overflow: auto;
        }

        @media (min-width: map.get(breakpoints.$grid-breakpoints, 'lg')) {
          margin: 80px 0;
        }

        @media (min-width: map.get(breakpoints.$grid-breakpoints, 'md')) {
          //width: auto;
          // grid between: 6-10
          min-width: grid-widths.gridWidth(6, 'md') !important;
          max-width: grid-widths.gridWidth(10, 'md') !important;
        }

        @media (min-width: map.get(breakpoints.$grid-breakpoints, 'lg')) {
          // grid between: 4 - 8
          min-width: grid-widths.gridWidth(4, 'lg') !important;
          max-width: grid-widths.gridWidth(8, 'lg') !important;
        }

        @media (min-width: map.get(breakpoints.$grid-breakpoints, 'xl')) {
          // grid between: 4 - 8
          min-width: grid-widths.gridWidth(4, 'xl') !important;
          max-width: grid-widths.gridWidth(8, 'xl') !important;
        }

        @media (min-width: map.get(breakpoints.$grid-breakpoints, 'xxl')) {
          // grid between: 4 - 8
          min-width: grid-widths.gridWidth(4, 'xxl') !important;
          max-width: grid-widths.gridWidth(8, 'xxl') !important;
        }
      }

      // Dialog content styling
      .mat-mdc-dialog-surface {
        margin: 0;
        padding: sizes.$medium;
        border-radius: 3px;

        .mat-mdc-dialog-title {
          &::before {
            display: none;
          }

          padding: 0;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          margin: 0;
          width: 100%;
          min-height: sizes.$large;

          & > h2 {
            margin: 0;
            justify-self: flex-start;
          }

          button[mat-dialog-close] {
            align-self: baseline;
            margin-top: (-(sizes.$small));
          }
        }

        .mat-mdc-dialog-content {
          // set the base color of text in the dialog
          --mdc-dialog-supporting-text-color: theme.$font-standard-color;

          margin: sizes.$medium (-(sizes.$medium));
          height: auto;
          // please check ticket https://akelius.atlassian.net/browse/AKDS-335 this to avoid the label cutting off
          padding: sizes.$extra-small sizes.$medium 0 sizes.$medium;
        }

        .mat-mdc-dialog-actions {
          padding: 0;

          button,
          .mdc-button,
          .dialog-action-item {
            margin: sizes.$medium 0 0 sizes.$medium;
          }
        }
      }
    }
  }
}

// Helper class to make the content grow based on available screen height
// Add .modal-flex-content to the element which contains .mat-mdc-dialog-title, .mat-mdc-dialog-content and .mat-mdc-dialog-actions
.cdk-overlay-container .cdk-global-overlay-wrapper .mat-mdc-dialog-container .modal-flex-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: stretch;

  & .mat-mdc-dialog-title {
    flex: 0 0 auto;
  }

  & .mat-mdc-dialog-content {
    flex: 1 1 100%;
  }

  & .mat-mdc-dialog-actions {
    flex: 0 0 auto;
  }
}
