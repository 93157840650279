@use '../style/private-theme' as theme;
@use '../style/utils';
@use '../style/sizes';

// remove the border from the calendar period button
#{utils.raiseSpecificity('.mat-calendar')} {
  // make the text and the down arrow the same color
  * {
    color: theme.$font-standard-color;
    font-size: sizes.$font-size;
  }

  .mat-calendar-arrow {
    fill: theme.$font-standard-color;
  }

  // remove the outline from the calendar period button
  .mat-calendar-period-button {
    box-shadow: none;
  }
}
