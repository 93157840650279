@use '../style/private-theme' as theme;
@use '../style/sizes';
@use '../style/utils';

#{utils.raiseSpecificity('.mat-expansion-panel')} {
  &:not([class*='mat-elevation-z']) {
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid theme.$line-color;
  }

  &[disabled] .mat-expansion-panel-header .mat-expansion-panel-header-title {
    // needed because the icon version of the arrow has a different width - see comment below on ::before
    margin-left: 0;

    & > h2,
    h3 {
      color: theme.$disabled-color;
    }

    // because the indicator arrow is not shown by Material when the expansion is disabled,
    // we use a font ligature to show the material icons version of the arrow and style it
    //to match the standard indicator
    &::before {
      font-family: 'Material Icons';
      -webkit-font-feature-settings: 'liga';
      content: 'expand_more';
      display: flex;
      align-items: center;
      width: 24px;
      height: 100%;
      color: theme.$disabled-color;
      font-size: 22px;
      margin: 0 4px 0 2px;
    }
  }

  &.mat-expansion-panel-spacing {
    margin: 0;
  }

  .mat-expansion-panel-header {
    font-size: sizes.$font-size;
    padding: sizes.$medium 0;
    height: auto;

    .mat-expansion-panel-header-title {
      margin-left: sizes.$small;

      & > h2,
      h3 {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    span.mat-expansion-indicator {
      margin: 0 sizes.$small;
      // we use flex to keep the arrow centered
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mat-expansion-indicator::after {
      border-color: theme.$accent-color;
    }

    &.mat-expanded {
      height: auto;
    }
  }

  .mat-expansion-panel-content {
    font-size: sizes.$font-size;

    .mat-expansion-panel-body {
      padding: sizes.$medium sizes.$medium sizes.$medium sizes.$large;
    }
  }
}
