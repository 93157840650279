@use '../style/theme';
@use '../style/sizes';
@use '../style/utils';

#{utils.raiseSpecificity('.mat-mdc-autocomplete-panel')} {
  // visibility class used for extra specificity
  &.mat-autocomplete-visible {
    max-height: 220px;
  }

  .mat-mdc-option {
    border-bottom: 1px solid theme.$line-color;

    .mat-pseudo-checkbox::after {
      color: theme.$accent-color;
    }
  }

  .mat-mdc-option.mdc-list-item--selected:not(.mat-active) {
    background-color: theme.$primary-color;

    .mdc-list-item__primary-text {
      color: theme.$primary-text-color;
    }
  }

  .mat-mdc-option {
    &:not(.mat-mdc-option-disabled),
    &.mat-mdc-option:focus:not(.mat-option-disabled) {
      // checkboxes for single select selected status
      .mat-pseudo-checkbox-minimal {
        background-color: theme.$primary-color;

        &::after {
          background-color: theme.$primary-color;
          color: theme.$primary-text-color !important;
        }
      }
    }
  }
}
