@use '../style/private-theme' as theme;
@use '../style/sizes';
@use '../style/utils';
@use '../mixins/border-shadow.mixin';
@use '../mixins/utilities.mixin';

$icon-font-size: 32px;
$large-icon-size: 48px;

@mixin large-icon-button() {
  font-size: 32px;

  mat-icon {
    font-size: 32px;
  }

  mat-icon,
  mat-icon svg {
    height: 32px;
    width: 32px;
  }
}

@mixin button-base() {
  font-size: sizes.$font-size;
  height: 40px;
  min-width: 48px;
  text-decoration: none;
  -webkit-font-smoothing: initial;
}

@mixin button-colors($color, $background-color) {
  background-color: $background-color;
  color: $color;
}

@mixin button-borders($border-color) {
  @include border-shadow.border-shadow($border-color);
}

//
// *** FLAT BUTTONS ***
//

// we use two classes to increase the specificity since otherwise the box
// shadow gets overwritten
#{utils.raiseSpecificity('.mdc-button')} {
  @include button-base();
  @include button-colors(theme.$button-standard-text-color, theme.$button-standard-background-color);
  @include button-borders(theme.$button-standard-border-color);
  border-radius: theme.$button-border-radius;

  .mat-icon {
    margin-right: sizes.$extra-small;
    height: 24px;
    width: 24px;
  }

  &.green {
    @include button-colors(white, theme.$status-success-color);
    @include button-borders(theme.$status-success-color);
  }

  &.red {
    @include button-colors(white, theme.$status-error-color);
    @include button-borders(theme.$status-error-color);
  }

  &.yellow {
    @include button-colors(black, theme.$status-warning-color);
    @include button-borders(theme.$status-warning-color);
  }

  &.mat-primary {
    @include button-colors(theme.$text-color-on-primary-background, theme.$primary-color);
    @include button-borders(theme.$primary-button-border-color);
  }

  &[disabled] {
    @include button-colors(theme.$disabled-color, var(--mat-standard-button-toggle-disabled-state-background-color));
    @include button-borders(theme.$line-color);
  }
}

a.mdc-button {
  text-decoration: none;
}

//
// *** ICON BUTTONS ***
//

button.mdc-icon-button,
a.mdc-icon-button {
  color: theme.$icon-font-color;
  border-radius: 8px;

  .mdc-icon-button__ripple {
    border-radius: 8px;
  }

  &.icon-button-selected {
    color: theme.$text-color-on-primary-background;
    background-color: theme.$primary-color;
  }

  &.header-button {
    @include large-icon-button;
    border-radius: 8px;
    color: theme.$header-icon-color;
    border-color: transparent;
    padding: sizes.$small;
  }

  &.header-button[aria-expanded='true'] {
    color: theme.$text-color-on-primary-background;
    background-color: theme.$primary-color;
  }

  &.top-button {
    @include large-icon-button;
    border-radius: 8px;
    padding: sizes.$small;
    color: theme.$button-standard-text-color;
    @include border-shadow.border-shadow(none);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.32);
    &:active,
    &.active {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.48);
    }
  }
}

button.mdc-icon-button[disabled][disabled],
a.mdc-icon-button[disabled][disabled] {
  color: theme.$disabled-color;
}

// Toggle buttons
#{utils.raiseSpecificity('.mat-button-toggle-group')} {
  .mat-button-toggle-checked {
    color: theme.$text-color-on-primary-background;
    background-color: theme.$primary-color;
  }
}
