@use '../style/theme';
.ripple-hover-overlay,
.ripple-hover-overlay-after {
  position: relative;
}

.ripple-hover-overlay:hover::before,
.ripple-hover-overlay-after:hover::after {
  content: '';
  background-color: theme.$ripple-color;
  opacity: 0.04;
  border-radius: inherit;
  position: absolute;
  inset: 0;
  pointer-events: none;
}
